@tailwind base;

h1 {
  text-shadow: 3px 3px #BF223C;
}
h2 {
  @apply text-xl;
}
h3 {
  @apply text-lg;
}
body {
  @apply bg-indigo;
}
button {
  @apply bg-pink text-red border-2 border-red font-bold py-2 px-4 rounded;
}

a {
  @apply text-indigo underline;
}

p {
  @apply leading-loose font-light;
}

.workers {
  text-shadow: 1px 1px #F7F1F8;
}

@tailwind components;

@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
